import React, { useState, useEffect, forceUpdate } from "react";

import Card from "./utils/Card.jsx"
import AddDayLayout from "./utils/AddDayLayout.jsx"
import DragDropLayout from "./utils/DragDropLayout/index.jsx"

import { getFirestore, collection, query, where, getDocs, getDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { getStorage, ref, getDownloadURL, deleteObject } from "firebase/storage";

import $ from 'jquery';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';


if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}

const db = getFirestore(firebase.app());

const q = query(collection(db, "days"));

function Days() {
  const [docsObjects, setDocs] = useState([]);
  const [imgUrl, setImgUrl] = React.useState(null);
  const [dayDoc, setDayDoc] = React.useState(null);
  const [activeCardTitle, setActiveCardTitle] = React.useState("");
  const [activeCardId, setActiveCardId] = React.useState("");
  const [activeCardDivisa, setActiveCardDivisa] = React.useState("");
  const [activeCardProve, setActiveCardProve] = React.useState("--");
  const [activeCardSalmo, setActiveCardSalmo] = React.useState("--");
  const [activeCardPreghiera, setActiveCardPreghiera] = React.useState("");
  const [cardIsActive, setCardIsActive] = React.useState(false);

  const storage = getStorage();
  getDownloadURL(ref(storage, 'utils/coro_logo.jpg')).then((url) => {
    setImgUrl(url);
  }).catch((error) => { });

  const openCard = (dayDoc, title, id, divisa, prove, salmo, preghiera, passwordEsterni) => {
    setActiveCardTitle(title);
    const storage = getStorage();
    setActiveCardId(id);
    setDayDoc(dayDoc);
    document.getElementById("card").style.height = "100%";
    setCardIsActive(true)
    setActiveCardDivisa(divisa)
    setActiveCardProve(prove)
    setActiveCardSalmo(salmo)
    setActiveCardPreghiera(preghiera)
    $("#divisaSelect").val(divisa);
    $("#password_esterni").val(passwordEsterni);
  }

  let docsObjectsList = docsObjects.map((doc) =>
    <Card key={doc.id} docId={doc.id} dayDoc={doc} title={doc.data().title} date={doc.data().date} divisa={doc.data().divisa} prove={doc.data().prove} salmo={doc.data().salmo} preghiera={doc.data().preghiera} imgUrl={imgUrl} passwordEsterni={doc.data().passwordEsterni} onClick={openCard} />
  );

  docsObjectsList.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
    return new Date(b.props.date) - new Date(a.props.date);
  });

  useEffect(() => {
    retriveData();
    console.log("retriving");
  }, [])

  useEffect(() => {
    docsObjectsList = docsObjects.map((doc) =>
      <Card key={doc.id} docId={doc.id} dayDoc={doc} title={doc.data().title} date={doc.data().date} divisa={doc.data().divisa} prove={doc.data().prove} salmo={doc.data().salmo} preghiera={doc.data().preghiera} imgUrl={imgUrl} passwordEsterni={doc.data().passwordEsterni} onClick={openCard} />
    );
    docsObjectsList.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
      return new Date(b.props.date) - new Date(a.props.date);
    });
  }, [docsObjects])

  async function retriveData() {
    const querySnapshot = await getDocs(q);
    setDocs([]);
    querySnapshot.forEach(async (doc) => {
      setDocs(oldArray => [...oldArray, doc]);
    });
  }

  function reloadComponent() {
    $("#cardsD").load(window.location.href + "#cardsD");
  }

  async function openDeleteDialog() {
    if (window.confirm("Eliminare il giorno?")) {
      await deleteDoc(doc(db, "days", activeCardId));
      reloadComponent();
    }
  }

  function editDayTitlePrompt(){
    let newTitle = prompt('Inserisci il nuovo nome dell\' evento');
    if(newTitle == "" || newTitle == null){
      return;
    }
    updateDoc(doc(query(collection(db, "days")), activeCardId), {
      ["title"]: newTitle
    })
    setActiveCardTitle(newTitle);
  }

  async function setPasswordEsterni(){
    await updateDoc(dayDoc.ref, {
      "passwordEsterni": $("#password_esterni").val()
    });
  }

  return (
    <div className="flex w-full ml-52 px-16 pb-16 flex-col">
      <AddDayLayout reloadComponent={reloadComponent} />

      <div id="cardsD" className="self-start justify-start flex flex-wrap px-20">
        {docsObjectsList}
      </div>

      <div id="card" className="overlay">
        <a href="#" className="closebtn" onClick={function () {
          document.getElementById("card").style.height = "0%";
          setCardIsActive(false)
        }}>&times;</a>
        <a href="#" className="mx-4 my-5 h-7 closebtn" onClick={openDeleteDialog}><FaTrashAlt className="h-7 closebtn" /></a>
        <div className="overlay-content">
          <div className="flex flex-row absolute m-32 gap-8">
            <div className="flex flex-col">
              <div className="flex w-fit items-center gap-4">
                <div>Divisa: </div>
                <select className={`h-8 my-4 ml-auto mr-6`} id="divisaSelect" onChange={(e) => setActiveCardDivisa(e.target.value)}>
                  <option value="no">no</option>
                  <option value="polo/felpa">polo/felpa</option>
                  <option value="sì">sì</option>
                </select>
              </div>
              <div className="flex w-fit items-center gap-4 h-8 my-4 ml-auto mr-6">
                <div>Orario Prove: </div>
                <input type="time" name="prove" id="prove" value={activeCardProve} onChange={(e) => setActiveCardProve(e.target.value)} />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex w-fit items-center gap-4">
                <div>Salmo resp.: </div>
                <select className={`h-8 my-4 ml-auto mr-6`} value={activeCardSalmo} onChange={(e) => setActiveCardSalmo(e.target.value)}>
                  <option value="--">--</option>
                  <option value="Massimiliano L.">Massimiliano L.</option>
                  <option value="Alessandra D.">Alessandra D.</option>
                  <option value="Andreina V.">Andreina V.</option>
                  <option value="Ylenia G.">Ylenia G.</option>
                  <option value="Tina A.">Tina A.</option>
                  <option value="Giulia S.">Giulia S.</option>
                  <option value="Flora D.P.">Flora D.P.</option>
                  <option value="Maria C.">Maria C.</option>
                  <option value="Genitori">Genitori</option>
                  <option value="Altri">Altri</option>
                </select>
              </div>
              <div className="flex w-fit items-center gap-4">
                <div>Preg. fedeli: </div>
                <select className={`h-8 my-4 ml-auto mr-6`} value={activeCardPreghiera} onChange={(e) => setActiveCardPreghiera(e.target.value)}>
                  <option value="--">--</option>
                  <option value="Massimiliano L.">Massimiliano L.</option>
                  <option value="Alessandra D.">Alessandra D.</option>
                  <option value="Andreina V.">Andreina V.</option>
                  <option value="Ylenia G.">Ylenia G.</option>
                  <option value="Tina A.">Tina A.</option>
                  <option value="Giulia S.">Giulia S.</option>
                  <option value="Flora D.P.">Flora D.P.</option>
                  <option value="Maria C.">Maria C.</option>
                  <option value="Delia N.">Delia N.</option>
                  <option value="Genitori">Genitori</option>
                  <option value="Altri">Altri</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex w-fit items-center gap-4 m-6 ml-0">
                <div>Aggiungi password per esterni:</div>
                <input className="border-black border" type="text" name="password_esterni" id="password_esterni" />
              </div>
            </div>
          </div>
          <div className="h2_container"><h2>{activeCardTitle}</h2><FaEdit className="h-8 w-8" id="editChantTitle" onClick={() => editDayTitlePrompt()}></FaEdit></div>
          <div>
            <DragDropLayout closeAction={function () {
              document.getElementById("card").style.height = "0%";
              let docsToUpdate = docsObjects;
              setDocs([]);
              docsToUpdate.forEach(async (docc) => {
                if (docc.id == activeCardId) {
                  getDoc(doc(q, docc.id)).then((doccc) => {
                    docsToUpdate[docsToUpdate.indexOf(docc)] = doccc;
                    setDocs(docsToUpdate)
                  })
                }
              })
              setCardIsActive(false)
              setPasswordEsterni();
            }} dayDoc={dayDoc} docId={activeCardId} cardActive={cardIsActive} divisa={activeCardDivisa} prove={activeCardProve} salmo={activeCardSalmo} preghiera={activeCardPreghiera} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Days;
