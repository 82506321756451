import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";

function Navbar() {
  const logOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div className="noselect flex w-max float-left fixed">
      <div className={`w-72 bg-dark-purple h-screen p-5  pt-8 relative duration-300`}>
        <div className="flex gap-x-4 items-center">
          <h1 className={`text-white origin-left font-medium text-xl duration-200`}>
            Gestione Coro
          </h1>
        </div>
        <ul className="pt-6">
            <li
              className={`mt-2 flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-000 text-sm items-center gap-x-4 text-[#FFF]
              ${window.location.pathname == "/chants" && "bg-light-white"} `}>
              <a href="/chants" className={`w-full origin-left duration-200`}>
                Lista Canti
              </a>
            </li>
            <li
              className={`mt-2 flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-000 text-sm items-center gap-x-4 text-[#FFF]
              ${window.location.pathname == "/days" && "bg-light-white"} `}>
              <a href="/days" className={`w-full origin-left duration-200`}>
                Lista Giorni
              </a>
            </li>
            <li
              className={`mt-9 flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-000 text-sm items-center gap-x-4 text-[#FFF]`}>
              <button onClick={logOut} className={`w-full text-left origin-left duration-200`}>
                Esci
              </button>
            </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
