import logo from './logo.svg';
import './App.css';
import Form from './app/utils/LoginForm.jsx'
import Manage from './app/Manage.jsx'

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
  authDomain: "sitocoro-e5767.firebaseapp.com",
  projectId: "sitocoro-e5767",
  storageBucket: "sitocoro-e5767.appspot.com",
  messagingSenderId: "516021736965",
  appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
  measurementId: "G-02F3QZXJFH"
})

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);
  return (
    <div className="App">
    <section>
      {user ? <Manage/> : <Form />}
    </section>
    </div>
  );
}

export default App;
