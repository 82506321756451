import "./utils/components/searchbox.css"

import Card from "./utils/Card.jsx"
import AddChantLayout from "./utils/AddChantLayout.jsx"
import CardsWrap from "./utils/CardsWrap.jsx"

import React, { useState, useEffect, forceUpdate, useRef } from "react";

import { getFirestore, collection, query, where, getDocs, doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { getStorage, ref, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";

import $ from 'jquery';
import { FaTrashAlt, FaEdit, } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';


//firebase consts
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}
const db = getFirestore(firebase.app());

function Chants() {
  const [activeCardTitle, setActiveCardTitle] = React.useState("");
  const [activeCardPdf, setActiveCardPdf] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardTesto, setActiveCardTesto] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardStrumenti, setActiveCardStrumenti] = React.useState(`${process.env.PUBLIC_URL}/empty.pdf`);
  const [activeCardId, setActiveCardId] = React.useState("");
  const [uploadingFileType, setUploadingFileType] = React.useState("bassi");
  const [isBassiUploaded, setIsBassiUploaded] = React.useState(false);
  const [isTenoriUploaded, setIsTenoriUploaded] = React.useState(false);
  const [isSopraniUploaded, setIsSopraniUploaded] = React.useState(false);
  const [isContraltiUploaded, setIsContraltiUploaded] = React.useState(false);
  const [isCoroUploaded, setIsCoroUploaded] = React.useState(false);
  const [isSalmistaUploaded, setIsSalmistaUploaded] = React.useState(false);
  const [isSolistaUploaded, setIsSolistaUploaded] = React.useState(false);
  const [selectedPdfName, setSelectedPdfName] = React.useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [tagsSuggestions, setTagsSuggestions] = useState([]);

  const filteredTagsSuggestions = tagsSuggestions.filter((suggestion) =>
    suggestion.toLowerCase().includes(tagInput.toLowerCase())
  );

  function updateTagsSuggestions(updatedTags) {
    const tagsRef = doc(db, "utils", "existingTags");
    if (updatedTags != null) {
      let combinedSet = new Set(tagsSuggestions);
      updatedTags.forEach(tag => {
        combinedSet.add(tag);
      });
      setTagsSuggestions(Array.from(combinedSet));
      updateDoc(tagsRef, {
        tagsArray: Array.from(combinedSet)
      })
      return;
    }
    if (tagsSuggestions.length != 0) {
      return;
    }
    getDocumentsWithTags().then(documents => {
      let allTags = new Set(tagsSuggestions);  // Use the current state as the base

      documents.forEach(doc => {
        let tagsToAdd = [...new Set(doc.tags)];
        tagsToAdd.forEach(tag => allTags.add(tag));
      });

      // Convert the set to an array and update the Firestore document
      const updatedTagsSuggestions = [...allTags];
      setTagsSuggestions(updatedTagsSuggestions);  // Update the state

      updateDoc(tagsRef, {
        tagsArray: updatedTagsSuggestions
      });

    }).catch(error => {
      console.error("Error getting documents: ", error);
    });
  }

  function closeCard() {
    document.getElementById("card").style.height = "0%";
    setActiveCardPdf(`${process.env.PUBLIC_URL}/empty.pdf`);
    setActiveCardTesto(`${process.env.PUBLIC_URL}/empty.pdf`);
  }

  //on card click
  const openCard = (title, pdf, id, testo, strumenti, chantTags) => {
    updateTagsSuggestions();

    setActiveCardTitle(title);
    const storage = getStorage();
    setActiveCardId(id);
    getDownloadURL(ref(storage, pdf)).then((url) => {
      setActiveCardPdf(url);
      getMetadata(ref(storage, pdf))
        .then((metadata) => {
          console.log('Original File Name:', metadata.name);
          setSelectedPdfName(metadata.name);
        })
        .catch((metadataError) => {
          console.error('Error getting metadata:', metadataError);
        });
    }).catch((error) => { });
    console.log(activeCardTesto);
    getDownloadURL(ref(storage, testo)).then((url) => {
      setActiveCardTesto(url);
    }).catch((error) => { });
    getDownloadURL(ref(storage, strumenti)).then((url) => {
      setActiveCardStrumenti(url);
      console.log(url)
    }).catch((error) => { console.log(error) });
    document.getElementById("card").style.height = "100%";

    try {
      getDoc(doc(db, "chants", id)).then((doc) => {
        setTags(doc.data().tags);
        $("#chantTags").html("")
        doc.data().tags.forEach(tag => {
          const newTag = tag;
          $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
        });
      });
    } catch (error) {
      console.error("Error getting document:", error);
    }
    setUploadedFiles(id);
  }

  function appendSuffixToFilename(filename, suffix) {
    // Split the filename into its name and extension parts
    let parts = filename.split('.');

    // Assuming the last part is the extension, join everything except the last part
    let name = parts.slice(0, -1).join('.');

    // Append the suffix and then re-append the extension
    return name + suffix + '.' + parts[parts.length - 1];
  }

  function setUploadedFiles(id) {
    setIsFileUploaded("bassi", id)
    setIsFileUploaded("tenori", id)
    setIsFileUploaded("soprani", id)
    setIsFileUploaded("contralti", id)
    setIsFileUploaded("coro", id)
    setIsFileUploaded("salmista", id)
    setIsFileUploaded("solista", id)
  }

  //delete dialog
  async function openDeleteDialog() {
    if (window.confirm("Eliminare il file?")) {
      await deleteDoc(doc(db, "chants", activeCardId));
      const storage = getStorage();
      deleteObject(ref(storage, activeCardPdf)).then(() => {
        reloadComponent();
      }).catch((error) => { });
    }
  }

  //reload component
  function reloadComponent() {
    $("#cards").load(window.location.href + "#cards");
  }

  const childRef = useRef();

  function reloadLatestChant() {
    if (childRef.current) {
      childRef.current.reloadLatestChantInWrap();
    }
  }

  function triggerFileInput(type) {
    document.getElementById('fileInputAudio').click();
    setUploadingFileType(type)
  }

  function handleFileUpload() {
    const fileInput = document.getElementById('fileInputAudio');
    const uploadedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    if (uploadedFile) {
      const storage = getStorage();
      const filepath = 'files/' + activeCardId + "_" + uploadingFileType + ".mp3";
      uploadBytes(ref(storage, filepath), uploadedFile).then((snapshot) => {
        setTimeout(() => {
          document.getElementById('fileInputAudio').value = null;
          openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, activeCardStrumenti, tags);
        }, 2999);
      });
    }
  }

  function deleteAudioFile(type) {
    const storage = getStorage();
    const filepath = 'files/' + activeCardId + "_" + type + ".mp3";
    deleteObject(ref(storage, filepath)).then((snapshot) => {
      setTimeout(() => {
        openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, activeCardStrumenti, tags);
      }, 2999);
    });
  }

  function playAudioFile(type) {
    const storage = getStorage();
    const audioRef = ref(storage, 'files/' + activeCardId + "_" + type + ".mp3");
    const audioPlayer = document.getElementById('audioPlayer');
    audioPlayer.src = "";
    getDownloadURL(audioRef).then((url) => {
      audioPlayer.src = url;
      audioPlayer.play();
    }).catch((error) => {
      console.error('Error getting download URL:', error);
    });
  }

  async function setIsFileUploaded(type, id) {
    const storage = getStorage();
    const audioRef = ref(storage, 'files/' + id + "_" + type + ".mp3");
    getDownloadURL(audioRef).then((url) => {
      switch (type) {
        case 'bassi':
          setIsBassiUploaded(true);
          break;
        case 'tenori':
          setIsTenoriUploaded(true);
          break;
        case 'soprani':
          setIsSopraniUploaded(true);
          break;
        case 'contralti':
          setIsContraltiUploaded(true);
          break;
        case 'coro':
          setIsCoroUploaded(true);
          break;
        case 'salmista':
          setIsSalmistaUploaded(true);
          break;
        case 'solista':
          setIsSolistaUploaded(true);
          break;
      }
    }).catch((error) => {
      switch (type) {
        case 'bassi':
          setIsBassiUploaded(false);
          break;
        case 'tenori':
          setIsTenoriUploaded(false);
          break;
        case 'soprani':
          setIsSopraniUploaded(false);
          break;
        case 'contralti':
          setIsContraltiUploaded(false);
          break;
        case 'coro':
          setIsCoroUploaded(false);
          break;
        case 'salmista':
          setIsSalmistaUploaded(false);
          break;
        case 'solista':
          setIsSolistaUploaded(false);
          break;
      }
    });
  }

  function triggerPdfFileInput() {
    document.getElementById('fileInputPdf').click();
  }

  function triggerTestoFileInput() {
    document.getElementById('fileInputTesto').click();
  }

  function triggerStrumentiFileInput() {
    document.getElementById('fileInputStrumenti').click();
  }

  function handlePdfFileUpload() {
    $("#pdfUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputPdf');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + selectedPdfName;
    if (selectedFile) {
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#pdfUploadStatus").html("Caricamento completato");
        setActiveCardPdf(filepath);
        document.getElementById('fileInputPdf').value = null;
        openCard(activeCardTitle, filepath, activeCardId, activeCardTesto, activeCardStrumenti, tags);
        setTimeout(() => {
          $("#pdfUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function handleTestoFileUpload() {
    $("#testoUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputTesto');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + appendSuffixToFilename(selectedPdfName, "_testo");
    if (selectedFile) {
      updateDoc(doc(query(collection(db, "chants")), activeCardId), {
        ["testo"]: filepath
      });
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#testoUploadStatus").html("Caricamento completato");
        setActiveCardTesto(filepath);
        document.getElementById('fileInputTesto').value = null;
        openCard(activeCardTitle, activeCardPdf, activeCardId, filepath, activeCardStrumenti, tags);
        setTimeout(() => {
          $("#testoUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function handleStrumentiFileUpload() {
    $("#strumentiUploadStatus").html("Caricamento in corso...");

    const db = getFirestore(firebase.app());
    const storage = getStorage();
    const fileInput = document.getElementById('fileInputStrumenti');
    const selectedFile = fileInput.files[0];

    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now()
    })

    let filepath = 'files/' + appendSuffixToFilename(selectedPdfName, "_strumenti");
    if (selectedFile) {
      updateDoc(doc(query(collection(db, "chants")), activeCardId), {
        ["strumenti"]: filepath
      });
      uploadBytes(ref(storage, filepath), selectedFile).then(() => {
        $("#strumentiUploadStatus").html("Caricamento completato");
        setActiveCardStrumenti(filepath);
        document.getElementById('fileInputStrumenti').value = null;
        openCard(activeCardTitle, activeCardPdf, activeCardId, activeCardTesto, filepath, tags);
        setTimeout(() => {
          $("#strumentiUploadStatus").html("");
        }, 1000);
      });
    }
  }

  function editChantTitlePrompt() {
    let newTitle = prompt('Inserisci il nuovo nome del canto');
    if (newTitle == "" || newTitle == null) {
      return;
    }
    updateDoc(doc(query(collection(db, "chants")), activeCardId), {
      ["modified"]: -Date.now(),
      ["title"]: newTitle
    })
    setActiveCardTitle(newTitle);
  }

  async function getDocumentsWithTags() {
    // Query documents where 'tags' contains any element
    const q = query(collection(db, 'chants'), where('tags', '!=', []));
    const querySnapshot = await getDocs(q);
    const documents = [];

    querySnapshot.forEach((doc) => {
      documents.push(doc.data());
    });

    return documents;
  }

  function addTagFromSuggestion(target) {
    const newTag = $(target).html();
    $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
    setTags(prevTags => {
      const updatedTags = prevTags ? [...prevTags, newTag] : [newTag]
      updateTagsInFirestore(updatedTags);
      return updatedTags;
    });
  }

  useEffect(() => {
    // jQuery keypress handler
    $("#textBox").keypress(function (e) {
      if (e.which === 13 && this.value !== "") {
        const newTag = this.value;
        $("#chantTags").append(`<a href='#' class='tag'>${newTag}<span class="cross">✖</span></a>`);
        setTags(prevTags => {
          const updatedTags = prevTags ? [...prevTags, newTag] : [newTag]
          updateTagsInFirestore(updatedTags);
          setTagInput("");
          return updatedTags;
        });
      }
    });

    // jQuery click handler
    $(document).on('click', '.tag span', function () {
      const tagValue = $(this).parent().text().replace('✖', '').trim();
      setTags(prevTags => {
        const indexToRemove = prevTags.indexOf(tagValue);
        if (indexToRemove !== -1) {
          const updatedTags = prevTags.slice(0, indexToRemove).concat(prevTags.slice(indexToRemove + 1));
          updateTagsInFirestore(updatedTags);
          return updatedTags;
        }
      });
      $(this).parent().remove();
    });

    // Cleanup on component unmount
    return () => {
      $("#textBox").off('keypress');
      $(document).off('click', '.tag span');
    };
  }, [activeCardId, tags]); // Use activeCardId and tags as dependencies

  const updateTagsInFirestore = (updatedTags) => {
    if (activeCardId) {
      const docRef = doc(db, "chants", activeCardId);
      updateDoc(docRef, { tags: updatedTags, ["modified"]: -Date.now() })
        .then(() => updateTagsSuggestions(updatedTags))
        .catch(error => console.error("Error updating tags: ", error));
    } else {
      console.error("Invalid activeCardId");
    }
  };

  return (
    <div className="flex w-full ml-52 px-16 pb-16 flex-col">
      <AddChantLayout reloadComponent={reloadComponent} reloadLatestChant={reloadLatestChant} />

      <CardsWrap classes="wrapChants" onClick={openCard} ref={childRef} />

      <div id="card" className="overlay">
        <a href="#" className="closebtn" onClick={closeCard}>&times;</a>
        <a href="#" className="mx-2 my-5 h-8 closebtn" onClick={openDeleteDialog}><FaTrashAlt className="h-8 closebtn" /></a>
        <div className="overlay-content">
          <input type="file" id="fileInputPdf" accept=".pdf" className="hidden" onChange={handlePdfFileUpload} />
          <input type="file" id="fileInputTesto" accept=".pdf" className="hidden" onChange={handleTestoFileUpload} />
          <input type="file" id="fileInputStrumenti" accept=".pdf" className="hidden" onChange={handleStrumentiFileUpload} />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerPdfFileInput()} id="replacePdfInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Cambia PDF</div>
            <span id="pdfUploadStatus"></span>
          </div>
          <br />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerTestoFileInput()} id="replaceTestoInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Testo</div>
            <span id="testoUploadStatus"></span>
          </div>
          <br />
          <div className="uploadButtonContainer">
            <div onClick={() => triggerStrumentiFileInput()} id="replaceStrumentiInput" className="buttonFileUpload w-40 ml-28"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Strumenti</div>
            <span id="strumentiUploadStatus"></span>
          </div>
          <div className="h2_container"><h2>{activeCardTitle}</h2><FaEdit className="h-8 w-8" id="editChantTitle" onClick={() => editChantTitlePrompt()}></FaEdit></div>
          <div className="tags_container">
            Aggiungi tag:
            <div>
              <input type="textbox" id="textBox" autoComplete="off" value={tagInput} onChange={(e) => setTagInput(e.target.value)}></input>
              <ul className="tagsSuggestionList">
                {filteredTagsSuggestions.map((suggestion, index) => (
                  <li className="tagSuggestion" onClick={(e) => addTagFromSuggestion(e.target)} key={index}>{suggestion}</li>
                ))}
              </ul>
            </div>
            <br />
            <div id="chantTags" contentEditable="false"></div>
          </div>
          <div className="audioFiles">
            <div className={isBassiUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("bassi")}    ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Bassi</div>
            <div className={isTenoriUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("tenori")}   ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Tenori</div>
            <div className={isSopraniUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("soprani")}  ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Soprani</div>
            <div className={isContraltiUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("contralti")}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Contralti</div>
            <div className={isCoroUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("coro")}     ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Coro</div>
            <div className={isSalmistaUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("salmista")}     ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Salmista</div>
            <div className={isSolistaUploaded ? "audioFileUploaded audioFile" : "audioFile"} onClick={() => playAudioFile("solista")}     ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1024px-Speaker_Icon.svg.png" />Solista</div>
          </div>
          <div className="audioFilesUpload">
            <input type="file" id="fileInputAudio" accept=".mp3" className="hidden" onChange={handleFileUpload} />
            <div onClick={() => triggerFileInput("bassi")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Bassi</div>
            <div onClick={() => triggerFileInput("tenori")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Tenori</div>
            <div onClick={() => triggerFileInput("soprani")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Soprani</div>
            <div onClick={() => triggerFileInput("contralti")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Contralti</div>
            <div onClick={() => triggerFileInput("coro")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Coro</div>
            <div onClick={() => triggerFileInput("salmista")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Salmista</div>
            <div onClick={() => triggerFileInput("solista")} className="buttonFileUpload"><img src="https://www.svgrepo.com/show/28557/upload-sign.svg" />Solista</div>
          </div>
          <div className="audioFilesUpload">
            <div onClick={() => deleteAudioFile("bassi")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Bassi</div>
            <div onClick={() => deleteAudioFile("tenori")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Tenori</div>
            <div onClick={() => deleteAudioFile("soprani")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Soprani</div>
            <div onClick={() => deleteAudioFile("contralti")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Contralti</div>
            <div onClick={() => deleteAudioFile("coro")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Coro</div>
            <div onClick={() => deleteAudioFile("salmista")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Salmista</div>
            <div onClick={() => deleteAudioFile("solista")} className="buttonFileUpload"><img src="https://cdn-icons-png.freepik.com/512/1345/1345874.png" />Solista</div>
          </div>
          <audio id="audioPlayer" controls></audio>
          <iframe src={activeCardPdf + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
          <iframe src={activeCardTesto + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
          <iframe src={activeCardStrumenti + "#page=1&zoom=FitH"} className="pdfFrame"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Chants;
