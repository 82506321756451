import React, { useState } from "react";
import "./utils/Manage.css"
import Navbar from "./utils/Navbar.jsx"
import Chants from "./Chants.jsx"
import Days from "./Days.jsx"

function Manage() {
  let component
  switch(window.location.pathname){
    case "/":
      window.location.pathname = "/chants"
      break
    case "/chants":
      component = <Chants/>;
      break
    case "/days":
      component = <Days/>;
      break
  }
  return (
    <div className="inline-block">
      <Navbar/>
      {component}
    </div>
  );
};

export default Manage;
