import "./index.scss"

function Toast(props){
  return(
    <div className="toast">
        <div className="toast-content">
            <i className="fas fa-solid fa-check check"></i>

            <div className="message">
              <span className="text text-1">{props.title}</span>
              <span className="text text-2">{props.text}</span>
            </div>
        </div>
        <i className="fa-solid fa-xmark close"></i>

        <div className="progress"></div>
    </div>
  )
}

export default Toast;
