import "./Manage.css"
import React, { useEffect, useState } from "react";

function Card(props){
  const [dateText, setDateText] = useState("");

  useEffect(()=>{
    let cidate = new Date(props.date);
    var month = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"][cidate.getMonth()];
    setDateText(cidate.getDate() + " " + month + ' ' + cidate.getFullYear() + " ore " + cidate.getHours() + ":" + (cidate.getMinutes() < 10 ? "0"+cidate.getMinutes() : cidate.getMinutes()));
  }, [])

  if(props.file){
    return(
      <div className="noselect card" id={props.docId} onClick={() => props.onClick(props.title, props.file, props.docId, props.testo)}>
        <img className="aspect-square" src={props.imgUrl} alt="logo"/>
        <div className="container">
          <h4><b>{props.title}</b></h4>
        </div>
      </div>
    )
  }else{
    return(
      <div className="noselect cardSq flex flex-col" onClick={() => props.onClick(props.dayDoc, props.title, props.docId, props.divisa, props.prove, props.salmo, props.preghiera, props.passwordEsterni)}>
        <img className="dayImage" src={props.imgUrl} alt="logo"/>
        <div className="container">
          <h4>{props.title}</h4>
        </div>
        <div className="px-6 py-4 -mt-6 flex-1 flex flex-col justify-end">
          <h4 className=""><b>{dateText}</b></h4>
        </div>
      </div>
    )
  }
}

export default Card;
