import "./components/searchbox.css"

import Card from "./Card.jsx"
import AddChantLayout from "./AddChantLayout.jsx"

import React, { useState, useEffect, forceUpdate, forwardRef, useImperativeHandle } from "react";

import { getFirestore, collection, query, where, getDocs, doc, deleteDoc, updateDoc, limit, orderBy } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import { getStorage, ref, getDownloadURL, deleteObject, getBlob, uploadBytes } from "firebase/storage";

import $ from 'jquery';
import { FaTrashAlt } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';

const CARD_MIN = 9;

//firebase consts
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}
const db = getFirestore(firebase.app());
const q = query(collection(db, "chants"), orderBy("modified"));
const qLatestChant = query(collection(db, "chants"), orderBy("modified", "asc"), limit(1));

const CardsWrap = forwardRef((props, refe) => {
  const [docsObjects, setDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);

  // Get default image
  useEffect(() => {
    const storage = getStorage();
    getDownloadURL(ref(storage, 'utils/coro_logo.jpg')).then((url) => {
      setImgUrl(url);
    }).catch((error) => { });
  }, []);

  // Retrieve data once on component mount
  useEffect(() => {
    async function retriveData() {
      const querySnapshot = await getDocs(q);
      const allDocs = [];
      querySnapshot.forEach((doc) => {
        allDocs.push(doc);
      });
      setDocs(allDocs);
      setFilteredDocs(allDocs);
    }
    retriveData();
  }, []);

  // Function to load the latest chant
  async function loadLatestChant() {
    const querySnapshot = await getDocs(qLatestChant);
    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const alreadyExists = docsObjects.some(doc => doc.id === latestDoc.id);

      if (!alreadyExists) {
        const newDocs = [latestDoc, ...docsObjects];
        setDocs(newDocs);
        setFilteredDocs(filterChants(newDocs, document.getElementById('searchTerm').value)); // Apply current search filter
      }
    }
  }

  // Filter chants based on search input
  function filterChants(docs, filter) {
    const upperFilter = filter.toUpperCase();
    return docs.filter(doc => doc.data().title.toUpperCase().includes(upperFilter));
  }

  function searchChant(event) {
    const filter = event.target.value;
    const filtered = filterChants(docsObjects, filter);
    setFilteredDocs(filtered);
  }

  // Reload latest chant on some trigger (e.g., button click)
  const reloadLatestChantInWrap = () => {
    let input = document.getElementById('searchTerm');
    input.value = "";
    loadLatestChant(); // Load the latest chant
  }

  useImperativeHandle(refe, () => ({
    reloadLatestChantInWrap,
  }));

  // Map the filtered documents to Card components
  const docsObjectsList = filteredDocs.map((doc) =>
    <Card key={doc.id} docId={doc.id} file={doc.data().pdf} title={doc.data().title} testo={doc.data().testo} strumenti={doc.data().strumenti} chantTags={doc.data().tags || []} imgUrl={props.addButton ? "https://upload.wikimedia.org/wikipedia/commons/3/3b/Flat_plus_icon.svg" : imgUrl} onClick={() => props.onClick(doc.data().title, doc.data().pdf, doc.id, doc.data().testo, doc.data().strumenti, doc.data().tags || [])} />
  );

  return (
    <div className={`${props.classes}`}>
      <div className="search" id={`${props.classes.includes("wrapDay") ? "no" : "searchlist"}`}>
        <input type="text" className="searchTerm" id="searchTerm" onChange={searchChant} placeholder="Cerca" />
      </div>

      <div id="cards" className={"self-start justify-start flex flex-wrap flex-col my-10"}>
        {docsObjectsList}
      </div>
      <div className="h-20"></div>
    </div>
  );
});

export default CardsWrap;