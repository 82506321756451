import "./components/buttons.css"
import "./components/edittext.scss"
import Toast from "./components/toast/index.jsx"
import React, { useState } from "react";
import firebase from 'firebase/compat/app';
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCVkw91Ckv3bzc_Mu0fgzEcJpFVfNXureA",
    authDomain: "sitocoro-e5767.firebaseapp.com",
    projectId: "sitocoro-e5767",
    storageBucket: "sitocoro-e5767.appspot.com",
    messagingSenderId: "516021736965",
    appId: "1:516021736965:web:c88ea336d85ff91bf12e6c",
    measurementId: "G-02F3QZXJFH"
  })
}

const db = getFirestore(firebase.app());

function AddDayLayout(props){
  const [toastTitle, setToastTitle] = useState("");
  const [toastDesc, setToastDesc] = useState("");
  const [addDayText, setAddDayText] = useState("Nuovo giorno");

  const [dateText, setDateText] = useState("Seleziona una data -->");

  let timer1, timer2;
  const toast = document.querySelector(".toast");
  const closeIcon = document.querySelector(".close");
  const progress = document.querySelector(".progress");

  function addDayClick(){
    if(addDayText == "Nuovo giorno"){
      setAddDayText("Aggiungi");
      document.getElementById("infoForm").classList.remove("hidden");
      document.getElementById("dateForm").classList.remove("hidden");
    }else{
      pushToDatabase();
    }
  }

  function showCalendar(){
    let ci = document.querySelector('label.calendar input[type=datetime-local]');
    ci.showPicker();
  }

  function dateChange(){
    let ci = document.querySelector('label.calendar input[type=datetime-local]');
    let cidate = new Date(ci.value);
    var month = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembrer", "dicembre"][cidate.getMonth()];
    setDateText(cidate.getDate() + " " + month + ' ' + cidate.getFullYear() + " ore " + cidate.getHours() + ":" + cidate.getMinutes());
  }

  async function pushToDatabase(){
    if(document.getElementById('title').value == "" || document.getElementById('date').value == ""){
      setToastTitle("Manca la data o il titolo");
      setToastDesc("Assicurati di aver selezionato data e ora e aver messo il titolo");
      toast.classList.add("active");
      timer1 = setTimeout(() => {
          toast.classList.remove("active");
      }, 2000);
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "days"), {
        title: document.getElementById('title').value,
        date: document.getElementById('date').value,
        divisa: "no",
        prove: thirtyMinutesBefore(document.getElementById('date').value),
        chants: [],
        salmo: "--",
        preghiera: "--"
      });
      setToastTitle("Messa aggiunta con Successo!");
      setToastDesc("Modifica i canti cliccando su di essa");
      setAddDayText("Nuovo canto");
      document.getElementById("infoForm").classList.add("hidden");
      document.getElementById("dateForm").classList.add("hidden");
      toast.classList.add("active");
      progress.classList.add("active");
      timer1 = setTimeout(() => {
          toast.classList.remove("active");
      }, 3000);
      timer2 = setTimeout(() => {
        progress.classList.remove("active");
        props.reloadComponent();
      }, 3300);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    document.getElementById('title').value = "";
    document.getElementById('date').value = "";
    setDateText("Seleziona una data");
  }

  function thirtyMinutesBefore(inputString) {
    // Extract hours and minutes from the input string
    var time = inputString.slice(-5); // Extract last 5 characters (hh:mm)
    var hours = parseInt(time.substring(0, 2));
    var minutes = parseInt(time.substring(3));

    // Convert hours and minutes to total minutes
    var totalMinutes = hours * 60 + minutes;

    // Subtract 30 minutes
    totalMinutes -= 30;

    // Handle the case when totalMinutes goes negative
    if (totalMinutes < 0) {
        totalMinutes += 24 * 60; // Add 24 hours' worth of minutes
    }

    // Convert total minutes back to hours and minutes
    var newHours = Math.floor(totalMinutes / 60);
    var newMinutes = totalMinutes % 60;

    // Format the result as hh:mm
    var result = (newHours < 10 ? '0' : '') + newHours + ':' + (newMinutes < 10 ? '0' : '') + newMinutes;

    return result;
}

  return(
    <>
    <Toast title={toastTitle} text={toastDesc}/>
    <div className="self-start w-full px-20 ml-20 py-20">
      <button className="float-left self-start button-5" onClick={addDayClick} role="button">{addDayText}</button>
      <div className="float-left px-14 self-start form__group field hidden" id="infoForm">
        <input type="input" autoComplete="off" className="form__field" placeholder="Title" name="title" id='title' required />
        <label htmlFor="title" className="form__label">Titolo</label>
      </div>
      <div className="flex hidden" id="dateForm">
        <div className="mx-14 my-3">{dateText}</div>
        <label htmlFor="file" className="button-5 calendar" onChange={dateChange} onClick={showCalendar}>Calendario<input id="date" type="datetime-local"/></label>
      </div>
    </div>
    </>
  )
}

export default AddDayLayout;
