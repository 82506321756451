import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./LoginForm.css";
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth";

const Form = () => {
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);


  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    let uname = document.getElementById("uname");
    let pass = document.getElementById("pass");

    const auth = getAuth();
    signInWithEmailAndPassword(auth, uname.value, pass.value).then((userCredential) => {
      setIsSubmitted(true);
    }).catch((error) => {
      console.error(error.message)
      setErrorMessages({ name: "uname", message: "Potresti aver sbagliato l'username o la password" });
    });
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" id="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" id="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title">Sign In</div>
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
};

export default Form;
